<template>
    <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
        <navbar-simple />

        <router-view />
    </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';

import NavbarSimple from '../components/NavbarSimple.vue';

export default {
    components: {
        NavbarSimple,
    },

    computed: {
        contentWidth() {
            const { contentWidth } = useAppConfig();
            return { contentWidth };
        },
    },
};
</script>
