<template>
    <!-- class="navbar navbar-shadow align-items-center" -->
    <b-navbar
        class="navbar header-navbar navbar navbar-shadow align-items-center navbar-light"
    >
        <div
            class="navbar-container container d-flex justify-content-between mx-auto"
        >
            <dark-toggler class="d-block" />

            <locale-dropdown />
        </div>
    </b-navbar>
</template>

<script>
import { BNavbar } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import LocaleDropdown from '@/layouts/components/LocaleDropdown.vue';

export default {
    components: {
        BNavbar,
        DarkToggler,
        LocaleDropdown,
    },

    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
};
</script>
